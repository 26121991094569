import SparklesIcon from "@heroicons/react/24/outline/SparklesIcon";
import CursorArrowRippleIcon from "@heroicons/react/24/solid/CursorArrowRippleIcon";
import StarIcon from "@heroicons/react/24/solid/StarIcon";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";
import ChatTailIcon from "../../../assets/svg/ai-summary-tail.svg";

export const SummaryWrapper = styling("div")({
  position: "sticky",
  top: "calc(165px + 52px)",

  height: "100%",
  width: "100%",
  maxWidth: "340px",

  marginBottom: "calc(32px + 25px + 16px)", // to align with job list page (pagination not included)
});

export const SummaryCard = styling("div")((props) => ({
  background: "#F7F7FF",
  border: `1px solid ${Color.secondaryGrey}`,
  borderRadius: "8px",
  overflow: "clip",

  ...(props.$JobSlotMax && {
    border: `2px solid ${Color.hiredlyPurple}`,
  }),
}));

export const SummaryContent = styling("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.625rem",
  padding: props.$JobSlotMax ? "0.5rem 0.75rem 0.75rem" : "0.75rem",
}));

export const PromotedContainer = styling("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",

  background:
    "linear-gradient(270deg, #512ACC 0%, #6D32C5 45.87%, #983EBA 101.73%)",

  padding: "0.25rem 1rem",
});

export const JobDetailsContainer = styling("div")({});

export const JobDetailsCard = styling("div")((props) => ({
  backgroundColor: Color.white,
  border: `0.5px solid ${Color.grey}`,
  borderRadius: "0px 6px 6px 6px",

  fontSize: "0.875rem",
  lineHeight: "1.5rem",
  letterSpacing: "0.15px",

  padding: "0.25rem 0.5rem",

  ...(props?.type === "skills" && {
    background: "#F7FFFA",
    borderRadius: "6px",

    fontSize: "0.75rem",
    lineHeight: "1.25rem",

    padding: "0.375rem 0.5rem",
  }),

  ...(props?.type === "benefits" && {
    background: "#FFFEF7",
    borderRadius: "6px",

    fontSize: "0.75rem",
    lineHeight: "1.25rem",

    padding: "0.375rem 0.5rem",
  }),
}));

export const PlaceholderWrapper = styling("div")({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  border: "2px dashed rgba(81, 42, 204, 0.20)",
  borderRadius: "20px",

  minHeight: "500px",
  maxWidth: "340px",
  padding: "20px 0",
});

export const BoxContainer = styling(Box)({
  display: "flex",
});

export const PromotedText = styling(Typography)({
  color: Color.white,
  fontSize: "0.625rem",
  fontWeight: "600",
  lineHeight: "1.25rem",
  letterSpacing: "1.5px",
  textTransform: "uppercase",
});

export const JobTitleText = styling(Typography)({
  fontSize: "0.75rem",
  fontWeight: "700",
  lineHeight: "1.25rem",
  textTransform: "capitalize",
});

export const SpanStyled = styling('span')({})

export const LinkStyled = styling("a")({
  color: Color.hiredlyPurple,
  textDecoration: "underline",
});

export const TextStyled = styling(Typography)({});

export const BetaText = styling(Typography)({
  color: Color.blue,

  fontSize: "0.625rem",
  fontWeight: "700",
  letterSpacing: "1px",

  padding: "0 0.625rem",
  margin: "0 -0.3125rem",
});

export const ShowMoreText = styling("span")({
  fontWeight: "700",
  cursor: "pointer",
});

export const AshleyImage = styling("img")({
  border: `2px solid ${Color.black}`,
  borderRadius: "50%",
  height: "34px",
  width: "34px",
});

export const PlaceholderImage = styling("img")({
  height: "340px",
  width: "auto",
});

export const AiArrowImage = styling("img")({
  position: "absolute",
  left: "0",
  bottom: "10px",
  width: "150px",
});

export const AiSummaryTail = styling(ChatTailIcon)({
  height: "17.5px",
  width: "auto",
  margin: "0 0 -6px -0.75px",
  zIndex: 1,
});

export const AiSparklesIcon = styling(SparklesIcon)({
  height: "16px",
  width: "16px",
});

export const HoverIcon = styling(CursorArrowRippleIcon)({
  height: "20px",
  width: "20px",
});

export const PromotedIcon = styling(StarIcon)({
  color: Color.white,
  height: "16px",
  width: "16px",
});
