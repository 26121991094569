import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import resumeProcess from "../../../assets/lotties/job-list/resume-parsing.json";
import resumeUpload from "../../../assets/lotties/job-list/resume-uploading.json";
import { convertBase64, setItem } from "../../../helpers/data_management";
import { getAshleyRecommendedJobs } from "../../../redux/actions/job_action";
import {
  checkResumeParsedStatus,
  newGetUserInfo,
  newResumeUpload,
  updateResumeBuildingStatus,
  updateResumeDoneStatus,
  updateResumeProgressPopupStatus,
  updateResumeUploadingStatus,
  updateUser,
} from "../../../redux/actions/user_action";
import * as types from "../../../redux/types/user_type";
import { BoxContainer, GreyText, LottieStyled, RoundedButton } from "../styles";
import {
  ButtonContainer,
  CardContainer,
  CardHeaderText,
  CardSubheadingText,
  CheckIcon,
  DragResumeElement,
  DropzoneContainer,
  HeaderContainer,
  HeaderText,
  InputStyled,
  LinkStyled,
  ResumeFailed,
  ResumeFailedText,
  ResumeFormatText,
  ResumeInReviewContainer,
  ResumeRejectedContainer,
  ResumeWrapper,
  SubheadingText,
} from "./styles";

export default function ForYouResume(props) {
  const { handleTabChange } = props;

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user?.user);

  const resumeExist = user?.resume?.length > 0 || user?.state != "no_resume";
  const resumeStatus = user?.state;

  const showDropzone = !resumeExist;
  const showRejected = resumeStatus === "rejected" && resumeExist;
  const showInReview = resumeStatus === "in_review" && resumeExist;

  async function handleCvFileChange(file, autoGenerated = false) {
    if (!file) return;

    if ((file.size / 1024 / 1024).toFixed(4) > 2) {
      setValidResume(false);
      setInvalidSize(true);
      return;
    }

    if (
      !(
        file.name?.includes(".docx") ||
        file.name?.includes(".doc") ||
        file.name?.includes(".pdf")
      )
    ) {
      setValidResume(false);
      setInvalidSize(false);
      return;
    }

    // Converting PDF file to base64
    convertBase64(file).then((cvFile) => {
      // Trigger graphQL to upload cvFile
      let params = {
        resume: cvFile,
        canCancel: autoGenerated,
        origin: "profile",
        user_resume: user.resume,
        autoGenerated: autoGenerated,
        state: autoGenerated ? "approved" : "review",
      };

      // Show progress popup
      dispatch(updateResumeProgressPopupStatus(true));

      // Hide building
      dispatch(updateResumeBuildingStatus(false));

      // Show uploading
      dispatch(updateResumeUploadingStatus(true));

      dispatch(newResumeUpload(params)).then((response) => {
        // Hide uploading
        dispatch(updateResumeUploadingStatus(false));

        if (response.type === "UPDATE_USER_CV_SUCCEED") {
          // Show done then hide done after 2 seconds
          dispatch(updateResumeDoneStatus(true));
          setTimeout(() => {
            dispatch(updateResumeProgressPopupStatus(false));
            dispatch(updateResumeDoneStatus(false));
          }, 2000);

          const message = (
            <>
              <span>Resume updated. </span>
              <GreyText>Reviewing...</GreyText>
            </>
          );

          toast.success(message, {
            timer: 3000,
          });

          // Update redux user information
          dispatch(updateUser(response.user));

          // Refetch user info after 5 minutes
          setTimeout(() => {
            refetchUserInfo(response.user);
          }, 300500);

          // Update local information
          setItem("USER_PROFILE", JSON.stringify(response.user));
        }
      });
    });
  }

  function refetchUserInfo(user) {
    const inputTime = dayjs(user?.uploadResumeAt);
    const currentTime = dayjs();
    const timePlusFiveMinutes = inputTime.add(5, "minute");

    if (timePlusFiveMinutes.isAfter(currentTime)) {
      dispatch(checkResumeParsedStatus()).then((response) => {
        if (response.type === types.CHECK_RESUME_PARSED_STATUS_SUCCEED) {
          dispatch(newGetUserInfo()).then(() => {
            const jobKey = new Date().getTime();

            const params = {
              currentLoadingJobsKey: jobKey,
              first: 50,
              last: null,
              startCursor: null,
              endCursor: null,
            };

            dispatch(getAshleyRecommendedJobs(params)).then(() => {
              const message = <span>Jobs updated.</span>;

              toast.success(message, {
                timer: 3000,
              });
            });
          });
        }
      });
    }
  }

  function handleFileChange(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleCvFileChange(e.target.files[0]);
      e.target.value = null;
    }
  }

  function handleExploreAllJobs() {
    handleTabChange(1);
  }

  return (
    <ResumeWrapper>
      {showDropzone && (
        <ResumeDropzone
          handleFileChange={handleFileChange}
          handleCvFileChange={handleCvFileChange}
          handleExploreAllJobs={handleExploreAllJobs}
        />
      )}
      {showInReview && (
        <ResumeInReview
          refetchUserInfo={refetchUserInfo}
          handleExploreAllJobs={handleExploreAllJobs}
        />
      )}
      {showRejected && (
        <ResumeRejected
          handleFileChange={handleFileChange}
          handleExploreAllJobs={handleExploreAllJobs}
        />
      )}
    </ResumeWrapper>
  );
}

function ResumeDropzone(props) {
  const { handleFileChange, handleCvFileChange, handleExploreAllJobs } = props;

  const user = useSelector((state) => state.user?.user);

  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  // handle drag events
  function handleDrag(e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }

  // triggers when file is dropped
  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (user?.state === "no_resume") {
        handleCvFileChange(e.dataTransfer.files[0]);
      }
    }
  }

  function handleBrowseFiles() {
    inputRef.current.click();
  }

  return (
    <>
      <HeaderContainer>
        <HeaderText>We're working on your personalised job feed</HeaderText>
        <SubheadingText>
          Upload your resume and we'll find your best matches!
        </SubheadingText>
      </HeaderContainer>

      <CardContainer>
        <BoxContainer flexDirection="column" alignItems="center">
          <CardHeaderText>Get better matches!</CardHeaderText>
          <CardSubheadingText>Upload your resume:</CardSubheadingText>
        </BoxContainer>
        <DropzoneContainer
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <LottieStyled
            options={{
              loop: true,
              autoplay: true,
              animationData: resumeUpload,
            }}
            width={"104px"}
            height={"80px"}
            style={{ margin: "0 0 1rem" }}
          />
          <RoundedButton
            variant="filled"
            size="medium"
            onClick={handleBrowseFiles}
          >
            Browse files
          </RoundedButton>
          <ResumeFormatText>
            Support file type: .pdf, doc, .docx
          </ResumeFormatText>
        </DropzoneContainer>
        <RoundedButton
          variant="outlined"
          color="secondary"
          size="small"
          onClick={handleExploreAllJobs}
        >
          Explore All Jobs
        </RoundedButton>
      </CardContainer>
      <InputStyled
        ref={inputRef}
        accept=".pdf,.docx,.doc"
        type="file"
        onChange={handleFileChange}
      />
      {dragActive && (
        <DragResumeElement
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </>
  );
}

function ResumeInReview(props) {
  const { refetchUserInfo, handleExploreAllJobs } = props;

  return (
    <>
      <CardContainer>
        <ResumeInReviewContainer>
          <CardHeaderText>
            <CheckIcon /> Resume under review
          </CardHeaderText>
          <LottieStyled
            options={{
              loop: true,
              autoplay: true,
              animationData: resumeProcess,
            }}
            width={"100px"}
            height={"100px"}
          />
          <CardSubheadingText>
            Give us a minute to process your resume for the best possible job
            matches. In the meantime, feel free to discover available jobs on
            our platform.
          </CardSubheadingText>
        </ResumeInReviewContainer>
        <ButtonContainer>
          <RoundedButton
            variant="outlined"
            color="secondary"
            onClick={handleExploreAllJobs}
          >
            Explore All Jobs
          </RoundedButton>
          <RoundedButton variant="filled" onClick={refetchUserInfo}>
            Refresh Page
          </RoundedButton>
        </ButtonContainer>
      </CardContainer>
    </>
  );
}

function ResumeRejected(props) {
  const { handleFileChange, handleExploreAllJobs } = props;

  const inputRef = useRef(null);

  function handleReupload() {
    inputRef.current.click();
  }

  return (
    <>
      <CardContainer>
        <ResumeRejectedContainer>
          <CardHeaderText>No available matches currently</CardHeaderText>
          <BoxContainer flexDirection="column" alignItems="center" gap="1rem">
            <ResumeFailed />
            <CardSubheadingText>
              Unfortunately, we couldn't find any jobs currently that matches
              the content in your uploaded resume. Try re-uploading your resume
              in a different format.
            </CardSubheadingText>
          </BoxContainer>
          <ResumeFailedText>
            If the problem persists, contact us at{" "}
            <LinkStyled
              target="_blank"
              rel="noopener"
              href="mailto:hello@hiredly.com?subject=No Job Recommendations Available&body=Dear Hiredly,<br><br>I am unable to view any job recommendations.<br><br>Please could you assist me with:"
            >
              hello@hiredly.com
            </LinkStyled>
          </ResumeFailedText>
        </ResumeRejectedContainer>
        <ButtonContainer>
          <RoundedButton
            variant="outlined"
            color="secondary"
            size="small"
            onClick={handleExploreAllJobs}
          >
            Explore All Jobs
          </RoundedButton>
          <RoundedButton
            variant="filled"
            color="secondary"
            size="small"
            onClick={handleReupload}
          >
            Reupload Resume
          </RoundedButton>
        </ButtonContainer>
      </CardContainer>
      <InputStyled
        ref={inputRef}
        accept=".pdf,.docx,.doc"
        type="file"
        onChange={handleFileChange}
      />
    </>
  );
}
