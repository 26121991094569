import ArrowPathIcon from "@heroicons/react/16/solid/ArrowPathIcon";
import DocumentArrowUpIcon from "@heroicons/react/24/outline/DocumentArrowUpIcon";
import ButtonBase from "@mui/material/ButtonBase";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";
import { P } from "../styles";

export const JobListWrapper = styling("div")({
  display: "flex",
  flexDirection: "column",

  maxWidth: "1440px",
  width: "100%",
  padding: "1rem 5rem 2rem",
  margin: "0 auto",

  "@media (max-width: 1280px)": {
    padding: "0",
  },

  "@media (max-width: 640px)": {
    justifyContent: "space-between",

    height: "calc(100svh - 220px)",

    "@media (max-height: 700px)": {
      height: "calc(100svh - 100px)",
    },
  },
});

export const JobListContainer = styling("div")({
  display: "flex",
  justifyContent: "center",
  gap: "0.5rem",

  maxHeight: "428px",
  height: "100%",

  overflowY: "hidden",
  overflowX: "auto",
  scrollBehavior: "smooth",
  scrollSnapType: "x mandatory",

  "@media (max-width: 1280px)": {
    justifyContent: "flex-start",
    maxHeight: "520px",
    padding: "0 calc(50% - 160px)",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  "@media (max-width: 640px)": {
    minHeight: "382px",
    height: "calc(100svh - 428px)",
    padding: "0 calc(50% - 148px)",
  },
});

export const HeaderContainer = styling("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  margin: "0 0 0.5rem",

  "@media (max-width: 1280px)": {
    gap: "1.5rem",
    margin: "0 0 1rem",
    padding: "1rem 1.5rem 0",
  },

  "@media (max-width: 640px)": {
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "0",

    padding: "1rem 1rem 0",
    margin: "0 0 0.5rem",
  },
});

export const HeaderButtonContainer = styling("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",
});

export const FooterContainer = styling("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",

  margin: "1.5rem 0 0",

  "@media (max-width: 1280px)": {
    margin: "2rem 0 0",
  },

  "@media (max-width: 640px)": {
    position: "sticky",
    bottom: "0",

    backgroundColor: Color.white,
    borderTop: "1px solid" + Color.secondaryGrey,

    gap: "0.5rem",

    padding: "1rem 1rem 1.5rem",
    margin: "0",
  },
});

export const HeaderText = styling(P)({
  color: "rgba(0, 0, 0, 0.87)",

  fontSize: "1rem",
  lineHeight: "24px",
  letterSpacing: "0.15px",

  padding: "0.375rem 0",

  "@media (max-width: 1280px)": {
    fontSize: "0.75rem",
    lineHeight: "20px",
  },
});

export const SquareButton = styling(ButtonBase)((props) => ({
  color: Color.darkGrey,
  backgroundColor: Color.white,
  border: "1px solid" + Color.secondaryGrey,
  borderRadius: "8px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",

  fontFamily: "Inter",
  fontSize: "0.875rem",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  whiteSpace: "nowrap",

  height: "36px",
  padding: "6px 10px",

  transition: "all 0.1s ease-in-out",

  "&:hover": {
    color: "rgba(0, 0, 0, 0.6)",
    border: "1px solid rgba(0, 0, 0, 0.6)",
    boxShadow: "0px 0px 0px 0.5px rgba(0, 0, 0, 0.6)",
  },

  "&:disabled": {
    color: Color.white,
    backgroundColor: Color.grey,
    border: "1px solid" + Color.grey,
    pointerEvents: "none",
  },

  ...(props.type === "select" && {
    "&:disabled": {
      color: Color.grey,
      backgroundColor: Color.white,
      border: "1px solid" + Color.white,
      pointerEvents: "none",
    },

    "& .hover-check": {
      transition: "all 0.15s ease-in-out",
      color: "transparent",
    },

    "&:hover": {
      "& .hover-check": {
        color: props?.selected ? Color.white : Color.black,
      },
    },
  }),

  ...(props.active && {
    color: "rgba(115, 95, 180, 1)",
    background: Color.lightPurple,
    border: "1px solid rgba(115, 95, 180, 1)",
    boxShadow: "0px 0px 0px 0.5px rgba(115, 95, 180, 1)",

    "&:hover": {
      color: Color.white,
      background: "rgba(115, 95, 180, 1)",
      border: "1px solid" + Color.darkPurple,
      boxShadow: "0px 0px 0px 0.5px" + Color.darkPurple,
    },
  }),
}));

export const ScrollIndicatorContainer = styling("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",

  "@media (max-width: 1280px)": {
    margin: "1rem 0",
  },

  "@media (max-width: 640px)": {
    margin: "0.625rem 0",
  },
});

export const ScrollIndicator = styling("div")((props) => ({
  border: "1px solid" + Color.darkPurple,
  borderRadius: "6.25rem",
  minWidth: "5px",
  minHeight: "5px",

  ...(props.selected && {
    backgroundColor: Color.darkPurple,
    minWidth: "10px",
    minHeight: "10px",
  }),
}));

export const ResumeIcon = styling(DocumentArrowUpIcon)({
  height: "20px",
  width: "20px",
});

export const RefreshIcon = styling(ArrowPathIcon)({
  height: "20px",
  width: "20px",
});
